section {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  border-radius: 20px;
  padding: 30px;
  background-color: white;
  position: relative;
}

body {
  background-color: #dae9ee;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

p {
  line-height: 1.4;
  color: #303030;
}

h1 {
  font-size: 50px;
  font-weight: 800;
  color: white;
}

h2 {
  font-size: 26px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 15px;
  font-weight: 500;
}

p {
  font-size: 15px;
  font-weight: 400;
}

h2.white {
  color: white;
}

.App {
  margin: auto;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*   justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.hero-box {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  padding: 10px;
  height: 100%;
  flex: 1;
  cursor: pointer;
}
